<template>
	<div class="error-403">
		<h3>Error <span>403</span></h3>
		<h5>您的访问受限</h5>
		<div v-if="errorInfo" style="color:#333;font-size: 0.8rem;">
			如需帮助请联系管理员
			<el-button type="primary" @click="connectAdmin" size="mini" class="ml-1">联系</el-button>
		</div>
	</div>
</template>

<script>
	import { decode } from 'js-base64'
	import {mapState} from 'vuex'
	import { sendToFeishu } from '@/apis/error'
	export default {
		inject: ['adminLayout'],
		data() {
			return {
				errorInfo: undefined
			}
		},
		computed: {
			...mapState({
				user : state => state.user.user
			})
		},
		mounted() {
			console.log('mouted 403')
			const {errMsg} = this.$route.query
			try {
				this.errorInfo = JSON.parse(decode(errMsg))
				console.log(this.errorInfo)
			} catch(e) {
				console.log(e)
			}
		},
		methods: {
			connectAdmin() {
				this.adminLayout.showLoading()
				const {fullPath, permission, cause} = this.errorInfo
				const {email} = this.user
				const content = cause ? `**${email}** 想访问 ${fullPath}, 特此申请权限 **原因${cause}**` : `**${email}** 想访问 ${fullPath}, 特此申请权限 **${permission}**`
				const body = {
				    "msg_type": "interactive",
				    "card": {
				        "config": {
				            "wide_screen_mode": true,
				            "enable_forward": false
				        },
				        "elements": [
				            {
				                "tag": "div",
				                "text": {
				                    "content": content,
				                    "tag": "lark_md"
				                }
				            }
				        ]
				    }
				}
				sendToFeishu(body)
				.then(({status, data: {StatusCode}}) => {
					if (status === 200 && StatusCode === 0) {
						this.$showSuccess('已通知管理员，请耐心等待')
					}
					this.adminLayout.hideLoading()
				})
				.catch(err => {
					console.error(err)
					this.adminLayout.hideLoading()
				})
			}
		},
		watch: {
			errMsg(newVal, oldVal) {
				try {
					this.errorInfo = JSON.parse(decode(newVal))
				} catch(e) {
					console.log(e)
				}
			}
		}
	}
</script>

<style>
</style>
